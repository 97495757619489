$icomoon-font-path: "../fonts";

$icon-keyboard_arrow_down: "\e313";
$icon-keyboard_arrow_left: "\e314";
$icon-keyboard_arrow_right: "\e315";
$icon-keyboard_arrow_up: "\e316";
$icon-home2: "\e90b";
$icon-image: "\e90f";
$icon-music: "\e911";
$icon-play: "\e912";
$icon-film: "\e913";
$icon-video-camera: "\e914";
$icon-dice: "\e915";
$icon-file-picture: "\e927";
$icon-quotes-left: "\e977";
$icon-quotes-right: "\e978";
$icon-spinner3: "\e97c";
$icon-switch: "\e9b6";
$icon-menu2: "\e9bd";
$icon-menu22: "\e9be";
$icon-menu3: "\e9bf";
$icon-menu4: "\e9c0";
$icon-plus: "\ea0a";
$icon-minus: "\ea0b";
$icon-cross: "\ea0f";
$icon-checkmark: "\ea10";
$icon-circle-up: "\ea41";
$icon-circle-right: "\ea42";
$icon-circle-down: "\ea43";
$icon-circle-left: "\ea44";
$icon-new-tab: "\ea7e";
$icon-mail2: "\ea84";
$icon-google-plus: "\ea8b";
$icon-twitter2: "\ea96";
$icon-youtube: "\ea9d";
$icon-vimeo: "\eaa0";
$icon-linkedin: "\eac9";
$icon-file-pdf: "\eadf";
$icon-file-openoffice: "\eae0";
$icon-file-word: "\eae1";
$icon-file-excel: "\eae2";
$icon-libreoffice: "\eae3";
$icon-search: "\e90d";
$icon-facebook: "\e900";
$icon-twitter: "\e902";
$icon-mail: "\e901";
$icon-print: "\e906";
$icon-info: "\e905";
$icon-download-pdf: "\e904";
$icon-download-path1: "\e907";
$icon-download-path2: "\e908";
$icon-download-path3: "\e909";
$icon-download-path4: "\e90a";
$icon-pfeil: "\e903";
$icon-pfeil-fett: "\ea34";
$icon-spinner: "\e97d";
$icon-menu: "\e90e";
$icon-rss: "\e90c";

