@import "base";
@import "webbear/webbear";
//@import "../bower_components/uikit/scss/uikit-variables";
//@import "../bower_components/uikit/scss/uikit-mixins";
//@import "../bower_components/uikit/scss/uikit";
@import "susy/susy";
@import "breakpoints/breakpoint";
@import "icons-variables";

$susy: (
  columns: 12,
  gutters: 1/8,
  math: fluid,
  output: float,
  gutter-position: inside,
);
* {
	@include box-sizing;
}
html,
body {
	font: 100%/1.5 "Helvetica", arial, sans-serif;
	height: 100%;
}

a {
	color: $link-color;
	text-decoration: underline;
	&:hover {
		color: $link-color-hover;
		text-decoration: none;
	}
}

body {
	.usa & {
		background: $usa-color;
	}
	.mond & {
		background: $mond-color;
		color: #fff;
	}
	.nordpol & {
		background: $nordpol-color;
	}
	.steinzeit & {
		background: $steinzeit-color;
	}
	.tibet & {
		background: $tibet-color;
	}
	.vikinger & {
		background: $vikinger-color;
	}
	.piraten & {
		background: $piraten-color;
	}
}

@include breakpoint($medium) {
	body {
		background-color: $background-color;
		.usa &,
		.mond &,
		.nordpol & ,
		.steinzeit & ,
		.tibet &,
		.vikinger &,
		.piraten & {
			background: $background-color;
		}
	}

}
img {
	max-width: 100%;
	height: auto;
}
object {
	max-width: 100%;

}

.container {
	min-height: 100%;
	@include breakpoint($medium) {
		max-width: 1200px;
		margin: 0 auto;
		border: 20px solid $border-color;
		border-image-source: url('../images/rahmen.png');
		border-image-slice: 18;
		border-image-repeat: round;
		border-image-width:initial;
	}

}
.page-wrapper {
	padding: 0 1rem;
	@include breakpoint($medium) {
		padding:0;
		@include container;
		border: 3px solid $black;
	}
}

.direct-links,
.print-copyright-and-source,
.utilities {
	@include visuallyhidden;
}

.logo {
	padding-top: 2rem;
	padding-bottom: 1rem;
  @include breakpoint($medium) {
  	padding-top: 1rem;
  	padding-bottom: 1rem;
  }
}

.logo,
.main-navigation {
	@include breakpoint($medium) {
		width: span(8 of 12);
		margin: 0 auto;
	}
}
/*
****************************************
* header
****************************************
*/

.header {
  position: relative;
}
.additionals {
  position: absolute;
  right:  0;
  top: 0;
  @include breakpoint($medium) {
    top: 2px;
    right:  12px;
  }
  a {
    font-family: $custom-font;
    text-transform: uppercase;
    text-decoration: none;
  }
}

/*
*********************************************
* main navigation
*********************************************
*/

.main-navigation {
	display: none;

	@include breakpoint($medium)  {
		display: block;
		position: relative;
		z-index: 3;
	}
	@include clearfix;
	ul, li {
		list-style: none;
	}

	.category.item_home {
		display: none;
	}
	.icon {
		display: block;
		width: 40px;
		height:40px;
		@include breakpoint($medium) {
			width: 60px;
			height: 60px;
		}
		margin: 0 auto;
		background-image: url(../images/icons/shows.svg);
		position:0 0;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.item_freunde .icon {
		background-image: url(../images/icons/freunde.svg);
	}
	.item_eltern .icon {
		background-image: url(../images/icons/eltern.svg);
	}
	.item_media .icon {
		background-image: url(../images/icons/media.svg);
	}
	.item_club .icon {
		background-image: url(../images/icons/club.svg);
	}
	.item_club .icon {
		background-image: url(../images/icons/club.svg);
	}
	.item_shop .icon {
		background-image: url(../images/icons/shop.svg);
	}
	.item_games .icon {
		background-image: url(../images/icons/games.svg);
	}
	 a {
		 text-decoration: none;
		 display: block;
		 text-transform: uppercase;
		 text-align: center;
		 font-weight: bold;
		 color: $white;

	 }
	 .selected a {
		 color: $selected-color;
	 }
	 li {
		 float: left;
		 width: 14.28571428%;
		 @include breakpoint($medium) {
		 	@include font-size(12);
		 }
		 @include breakpoint($medium) {
		 	@include font-size(16);
		 }
	 }

}
/*
*********************************************
* mobile navigation
*********************************************
*/
.mobile-navigation {
	@include breakpoint($medium) {
		display: none;
	}
	.button {
		display: block;
		width: 60px;
		height: 60px;
		margin: 0 auto;
		background-color: transparent;
		background-image: url(../images/icons/mobile-menu.svg);
		position:0 0;
		background-repeat: no-repeat;
		background-size: cover;
		cursor: pointer;

		&.on {
			background-image: url(../images/icons/mobile-menu-close.svg);
		}
	}
}
.mobile-nav {
	margin: 20px;
	ul, li {
		list-style: none;
	}
	.icon {
		display:inline-block;
		width: 40px;
		height: 40px;
		line-height: 30px;
		margin: 0 auto;
		background-image: url(../images/icons/shows.svg);
		position:0 0;
		background-repeat: no-repeat;
		background-size: cover;
		margin-right: 20px;
		vertical-align: middle;

	}
	.category {


		margin: 0 0 10px 0;
		li  {
			margin: 5px 0;
			a {
				vertical-align: middle;
				padding: 5px 20px;
				line-height: 30px;
				text-decoration: none;
				text-transform: uppercase;
				font-weight: bold;
				color: $black;
				background: $white;
				display: block;
				border-radius: 5px;
			}
		}
		ul {
			margin-left: 20px;
		}
	}
	.category >a {
		display: block;
		vertical-align: middle;
		padding: 5px 20px;
		background: $white;
		line-height: 30px;
		text-decoration: none;
		text-transform: uppercase;
		font-weight: bold;
		color: $black;
		border-radius: 5px;

	}
	li {
		position: relative;
	}
	.open-close-button {
		position: absolute;
		top: 3px;
		right:10px;
	}
	.category {
		> .open-close-button {
			top: 8px;
		}
	}
	.selected > a,
	.category .selected > a {
		color: $selected-color;
	}
	.item_media .icon {
		background-image: url(../images/icons/media.svg);
	}
	.item_freunde .icon {
		background-image: url(../images/icons/freunde.svg);
	}
	.item_shows .icon {
		background-image: url(../images/icons/shows.svg);
	}
	.item_games .icon {
		background-image: url(../images/icons/games.svg);
	}
	.item_club .icon {
		background-image: url(../images/icons/club.svg);
	}
	.item_eltern .icon {
		background-image: url(../images/icons/eltern.svg);
	}
	.item_shop .icon {
		background-image: url(../images/icons/shop.svg);
	}

}

/*
*********************************************
* sub navigation
*********************************************
*/
.sub-navigation {
	list-style: none;

	li {
		list-style: none;
	}
	a {
		text-decoration: none;
		font-weight: bold;
		text-transform: uppercase;
		font-style: italic;
		color: $white;
	}
	.selected>a {
		color: $red;
	}
	> ul > li ul {
		display: none;
		@include font-size(14);
		margin-left: 1em;
	}
	> ul > li.selected ul {
		display: block;
	}
}
.sub-navigation {
	margin-left: 1.75rem;
	margin-top: 1em;
}
/*
*********************************************
* layout body container
*********************************************
*/
.body-container {
	.website-startpage & {
		padding-top: 40px;
	}
	padding-bottom: 20px;
	@include clearfix;
}
.aside2	{
	display: none;
}
.shop-app .aside1 {
	display: block;
}

@include breakpoint($medium) {
	.aside1 {
		@include span(3 of 12);
		min-height: 400px;
		//background: red;
		//height: 400px;
		.gollo {
			margin: -20% 20% 0 -5% ;
		}
	}
	.main-column {
		@include span(6 of 12);
	}

	.aside2 {
		display: block;
		@include span(3 of 12 last);
		//background: green;
		//height: 400px;

		.papagallo {
			margin:  -20% 5% 0 0;
			img {
				//transform: scaleX(-1);
			}
		}
	}
}
.main-column {
	padding-top: 40px;
}


.footer {
	clear: both;

	@include breakpoint($medium) {
		height: 461px;
	}
}

.website-startpage {
	.ContentTitle {
		display: none;
	}
	.main-column {
		padding-top: 20px;
	}
	.container {
		background: $tibet-color;
	}
	.aside {
		display: none;
		@include breakpoint($medium) {
			display: block;
		}
	}
	.main-column {
		@include breakpoint($medium) {
			width: 100%;
		}
		@include breakpoint($medium) {
			@include span(6 of 12);
		}
	}
	.startpage-image {
		text-align: center;
	}
	.panel.facebook {
		text-align: right;
		padding-left: gutter(12);
		.fb-like-box {
			border: 5px solid $border-color;
			border-image-source: url('../images/rahmen.png');
			border-image-slice: 18;
			border-image-repeat: round;
			border-image-width:initial;

			>span,
			iframe {
				max-width: 100%;
			}
		}
	}
	.panel.news {
		border: 5px solid $border-color;
		border-image-source: url('../images/rahmen.png');
		border-image-slice: 18;
		border-image-repeat: round;
		border-image-width:initial;
		width: 210px;
		max-width: 100%;
		margin-right: gutter(12);
		min-height: 400px;
		background: #fff;
		padding: 6px;
		text-align: center;

		h2 {
			font-style: italic;
			margin: 12px 0;
			text-transform: uppercase;
		}
	}

	.footer {
		@include breakpoint($medium) {
			background: url("../images/footer/tibet-6699ff.jpg") 0 0 no-repeat;

		}

	}
}

.news-listing ul li{
	list-style: none;
	margin: 0 0 12px 0;
	text-transform: uppercase;
	a {
		color: $red;
	}
}
/*
*********************************************
* skin colors and images
*********************************************
*/

.container {
	background: $tibet-color;
	.usa & {
		background: $usa-color;
	}
	.mond & {
		background: $mond-color;
	}
	.nordpol & {
		background: $nordpol-color;
	}
	.steinzeit & {
		background: $steinzeit-color;
	}
	.tibet & {
		background: $tibet-color;
	}
	.vikinger & {
		background: $vikinger-color;
	}
	.piraten & {
		background: $piraten-color;
	}
}

@include breakpoint($medium) {
	.footer {
		background-image: url("../images/footer/tibet-6699ff.jpg");
		background-position: 0 bottom;
		background-repeat: no-repeat;
		background-size: 100% auto	;
		.tibet & {
			background-image: url("../images/footer/tibet-6699ff.jpg");
		}
		.usa & {
			background-image: url("../images/footer/usa-0099ff.jpg");
		}
		.mond & {
			background-image: url("../images/footer/mond-7a05c4.jpg");
		}
		.nordpol & {
			background-image: url("../images/footer/nordpol-0099cc.jpg");
		}
		.piraten & {
			background-image: url("../images/footer/piraten-017bc2.jpg");
		}
		.steinzeit & {
			background-image: url("../images/footer/steinzeit-0066cc.jpg");
		}
		.vikinger & {
			background-image: url("../images/footer/vikinger-1aa8e6.jpg");
		}
	}
}

//////////////////////////////////////////////
/// mobile first
//////////////////////////////////////////////

.sub-navigation-panel {
	display: none;
}
.shop-app {
	.sub-navigation-panel {
		display: block;
		div.gollo {
			display: none;
			@include breakpoint($medium) {
				display: block;
			}

		}
	}
}
@include breakpoint($medium) {
	.sub-navigation-panel {
		display: block;
		div.gollo {
			position: relative;

			.icon {
				display: block;
				width: 40px;
				height: 40px;
				margin: 0 auto;
				margin-top: -12%;
				@include breakpoint($medium) {
					width: 60px;
					height: 60px;
						margin-top: -9.5%;
				}

				background-image: url(../images/icons/shows.svg);
				position:0 0;
				background-repeat: no-repeat;
				background-size: cover;


				.media & {
					background-image: url(../images/icons/media.svg);
				}
				.freunde & {
					background-image: url(../images/icons/freunde.svg);
				}
				.shows & {
					background-image: url(../images/icons/shows.svg);
				}
				.games & {
					background-image: url(../images/icons/games.svg);
				}
				.club & {
					background-image: url(../images/icons/club.svg);
				}
				.eltern & {
					background-image: url(../images/icons/eltern.svg);
				}
				.shop & {
					background-image: url(../images/icons/shop.svg);
				}
			}
		}
	}
}
/*
*********************************************
* common definitions
*********************************************
*/

h1,
h2,
h3,
h4,
h5,
h6,
.main-navigation a,
.tree-navigation a,
.sub-navigation a {
	font-family: $custom-font;

}
table {
	width: 100%;
	border-collapse: collapse;
}

th,
td {
	padding: 5px ;
	border: 0 none;
	&:first-child {
		padding-left: 0;

	}
	&:last-child {
		padding-right: 0;
	}
}


th {
	text-align: left;
}

.main-column {
	.Text,
	.Image {
		margin: 0 0 20px 0;
		//color: $white;
	}
	.autoGenerated {
		margin-top: 20px;
	}
	h1.ContentTitle {
		@include font-size($h1);
		margin: 0 0 20px 0;
		text-align: center;
		text-transform: uppercase;
	}
	h2 {
		margin: 0 0 10px 0;
		@include font-size($h2);
	}
	.Download {
		margin: 0 0 10px 0;
	}
}
.Text,
.Download,
.Image {
	width: 100%;
	float: left;
	margin-bottom: 10px;
}



.partner-logos {
	@include clearfix;
	margin-bottom: 12px;
	div.partner-logo {
		float: left;
		width: auto;
		margin: 0 12px 12px 0;
	}
}

.Image.align-left {
	float: left;
	width: auto;
	margin: 0 12px 12px 0;
}

.Image.align-right {
	float: right;
	width: auto;
	margin: 0 0 12px 12px ;
}


.inline-icons {
	@include clearfix;
	margin-bottom: 12px;
	.inline-icon {
		float: left;
		width: auto;
		margin: 0 12px 12px 0;
	}
}

.gallery {
	img {
		max-width: 100%;
		height: auto;
	}
	@include clearfix;
	margin-bottom: 12px;
	.gallery-item {
		@include gallery(6 of 12);
		margin-bottom: gutter(12);
		@include breakpoint($medium) {
			@include gallery(4 of 12);
		}
		@include breakpoint($large) {
			@include gallery(3 of 12);
		}
	}
}
.gallery-rows-2items {
	@include clearfix;
	margin-bottom: 12px;
	img {
		max-width: 100%;
		height: auto;
	}
	.gallery-2items {
		margin-bottom: gutter(2);
		@include breakpoint($medium) {
			@include gallery(2 of 4);
		}
	}
}

@import "icons";

.Download {
	.SizeAndTargetInfo {
		@include visuallyhidden;
	}

	a:before {
		 font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	}

	a.pdf {
		&:before {
			content: $icon-file-pdf;
			margin-right: .5rem;
			text-decoration: none !important
		}
	}
}