@mixin box-sizing($value:border-box) {
	-moz-box-sizing:$value;
	-webkit-box-sizing:$value;
	box-sizing:$value;         // Opera/IE 8+
}

@mixin side-padding($right,$left:$right) {
	padding-right: $right;
	@if $left != $right {
		padding-left: $left;
	} @else {
		padding-left: $left;
	}
}

@mixin display($value:inline-block) {
	display: $value;
}

@mixin ie7-inline-block() {
    *display: inline;
    *zoom: 1;
}
@mixin clearfix() {
  zoom: 1;
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}

@mixin ir (){ border: 0; font: 0/0 a; text-shadow: none; color: transparent; background-color: transparent; }

// Hide from both screenreaders and browsers: h5bp.com/u
@mixin hidden (){ display: none !important; visibility: hidden; }

// Hide only visually, but have it available for screenreaders: h5bp.com/v
@mixin visuallyhidden (){ border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }

@mixin border-radius( $radius: 3px ) {

  border-radius: $radius;
}
@mixin text-shadow($value:1px 1px 1px black) {
	text-shadow: $value;
}

@mixin bg-linear-image(
    $start-color: #efefef,
    $end-color: #999

  ) {
	  background-color: $end-color;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($start-color), to($end-color));
  background-image: -moz-linear-gradient(0 100% 90deg, $end-color, $start-color);
  background-image: -webkit-linear-gradient(top, $start-color, $end-color); /*Chrome 10+, Saf5.1+, iOS 5+ */
  background-image:  -moz-linear-gradient(top, $start-color, $end-color); /* FF3.6 */
  background-image: -ms-linear-gradient(top, $start-color, $end-color); /* IE10 */
  background-image: -o-linear-gradient(top, $start-color, $end-color); /*Opera 11.10+ */
  background-image: linear-gradient(to bottom, $start-color, $end-color);

}

 @mixin box-shadow($value : 2px 2px 5px 0 rgba(0,0,0,.6)) {
	 box-shadow: $value;
}
@mixin border-bottom-radius($radius: 5px) {
	@include border-radius-bottom-left($radius);
	@include border-radius-bottom-right($radius);
}
@mixin border-radius-top-left($radius:5px) {

  border-top-left-radius: $radius;
}

@mixin border-radius-top-right($radius: 5px) {

  border-top-right-radius: $radius;
}
@mixin border-radius-bottom-right($radius: 5px) {

  border-bottom-right-radius: $radius;
}
@mixin border-radius-bottom-left($radius: 5px) {

  border-bottom-left-radius: $radius;
}


