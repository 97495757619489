$small: 37.5em;// 600px;
$medium: 50em; // 800px;
$large: 75em; //1200px;

$background-color: #ffffce;

$tibet-color: #6699ff;
$mond-color: #7a05c4;
$nordpol-color: #0099cc;
$piraten-color: #017bc2; // #0a87c9; //#017bc2;
$steinzeit-color: #0066cc;
$usa-color: #0099ff;
$vikinger-color: #1aa8e6;

$white: #fff;
$black: #000;
$yellow: #ffff01;

$red: #ed4221;

$border-color: $yellow;
$selected-color: $red;

$link-color: #fff;
$link-color-hover: $red;

//////////////////////////////////////////////
/// font sizes
//////////////////////////////////////////////


$h1: 26;
$h2: 24;
$h3: 18;
$h4-h6: 16;

$custom-font: 'Martel Sans', sans-serif;