/* @group Reset */

html, body, div, span, object, iframe,h1, h2, h3, h4, h5, h6, p, blockquote, pre,abbr, address, cite, code,del, dfn, em, img, ins, kbd, q, samp,small, strong, sub, sup, var,b, i,dl, dt, dd, ol, ul, li,fieldset, form, label, legend,table, caption, tbody, tfoot, thead, tr, th, td,main,article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary,time, mark, audio, video {
margin: 0;
padding: 0;
border: 0;
outline: 0;
font-size: 100%;
vertical-align: baseline;
background: transparent;
}

body {
line-height: 1;
}
// html,
// body {
// 	height: 100%;
// }

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section, main {
display: block;
}

blockquote, q {
quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
content: '"';
content: none;
}

a {
margin: 0;
padding: 0;
font-size: 100%;
vertical-align: baseline;
background: transparent;
}

:focus {
outline: 0;
}

/* change colours to suit your needs */
ins {
background-color: #ff9;
color: #000;
text-decoration: none;
}

/* change colours to suit your needs */
mark {
background-color: #ff9;
color: #000;
font-style: italic;
font-weight: bold;
}

del {
text-decoration: line-through;
}

abbr[title], dfn[title] {
border-bottom: 1px dotted;
cursor: help;
}

table {
border-collapse: collapse;
border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
display: block;
height: 1px;
border: 0;
border-top: 1px solid #cccccc;
margin: 1em 0;
padding: 0;
}


/* @end */


/* @group default margin/padding */

/*let's start with the lists*/
//#content ul,
//#content ol,
//#main-content ul,
//#main-content ol,
//#MainContent ul,
//#MainContent ol {
//margin: 0.4em 0;
//padding: 0 0 0 20px;
//}
//
//#content ol li,
//#main-content ol li,
//#MainContent ol li {
//	list-style: decimal;
//}
//
//#content ul li,
//#main-content ul li,
//#MainContent ul li {
//	list-style: disc;
//}

/* @end */

/* @group Forms */

form {
margin: 0;
}
legend {
border: 0; *margin-left:-7px;
text-shadow: 0 1px 1px #d4d4d4;
}

input, select, label {
vertical-align: middle;
}

button, input, select, textarea {
font-size: 100%;
}

button, input {
line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
box-sizing: border-box;
padding: 0;
}


input[type="search"] {
-webkit-appearance: textfield;
-moz-box-sizing: content-box;
-webkit-box-sizing: content-box;
box-sizing: content-box;
}



button::-moz-focus-inner,
input::-moz-focus-inner {
border: 0;
padding: 0;
}
input[type="search"]::-webkit-search-decoration {
-webkit-appearance: none;
}
textarea {
overflow: auto;
vertical-align: top;
}

/* @end */
