@charset "UTF-8";
/* @group Reset */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, main, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '"';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

:focus {
  outline: 0;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

/* @end */
/* @group default margin/padding */
/*let's start with the lists*/
/* @end */
/* @group Forms */
form {
  margin: 0;
}

legend {
  border: 0;
  *margin-left: -7px;
  text-shadow: 0 1px 1px #d4d4d4;
}

input, select, label {
  vertical-align: middle;
}

button, input, select, textarea {
  font-size: 100%;
}

button, input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

/* @end */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  font: 100%/1.5 "Helvetica", arial, sans-serif;
  height: 100%;
}

a {
  color: #fff;
  text-decoration: underline;
}

a:hover {
  color: #ed4221;
  text-decoration: none;
}

.usa body {
  background: #0099ff;
}

.mond body {
  background: #7a05c4;
  color: #fff;
}

.nordpol body {
  background: #0099cc;
}

.steinzeit body {
  background: #0066cc;
}

.tibet body {
  background: #6699ff;
}

.vikinger body {
  background: #1aa8e6;
}

.piraten body {
  background: #017bc2;
}

@media (min-width: 50em) {
  body {
    background-color: #ffffce;
  }
  .usa body,
  .mond body,
  .nordpol body,
  .steinzeit body,
  .tibet body,
  .vikinger body,
  .piraten body {
    background: #ffffce;
  }
}

img {
  max-width: 100%;
  height: auto;
}

object {
  max-width: 100%;
}

.container {
  min-height: 100%;
}

@media (min-width: 50em) {
  .container {
    max-width: 1200px;
    margin: 0 auto;
    border: 20px solid #ffff01;
    border-image-source: url("../images/rahmen.png");
    border-image-slice: 18;
    border-image-repeat: round;
    border-image-width: initial;
  }
}

.page-wrapper {
  padding: 0 1rem;
}

@media (min-width: 50em) {
  .page-wrapper {
    padding: 0;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    border: 3px solid #000;
  }
  .page-wrapper:after {
    content: " ";
    display: block;
    clear: both;
  }
}

.direct-links,
.print-copyright-and-source,
.utilities {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.logo {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

@media (min-width: 50em) {
  .logo {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media (min-width: 50em) {
  .logo,
  .main-navigation {
    width: 66.66667%;
    margin: 0 auto;
  }
}

/*
****************************************
* header
****************************************
*/
.header {
  position: relative;
}

.additionals {
  position: absolute;
  right: 0;
  top: 0;
}

@media (min-width: 50em) {
  .additionals {
    top: 2px;
    right: 12px;
  }
}

.additionals a {
  font-family: "Martel Sans", sans-serif;
  text-transform: uppercase;
  text-decoration: none;
}

/*
*********************************************
* main navigation
*********************************************
*/
.main-navigation {
  display: none;
  zoom: 1;
}

@media (min-width: 50em) {
  .main-navigation {
    display: block;
    position: relative;
    z-index: 3;
  }
}

.main-navigation:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.main-navigation ul, .main-navigation li {
  list-style: none;
}

.main-navigation .category.item_home {
  display: none;
}

.main-navigation .icon {
  display: block;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  background-image: url(../images/icons/shows.svg);
  position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 50em) {
  .main-navigation .icon {
    width: 60px;
    height: 60px;
  }
}

.main-navigation .item_freunde .icon {
  background-image: url(../images/icons/freunde.svg);
}

.main-navigation .item_eltern .icon {
  background-image: url(../images/icons/eltern.svg);
}

.main-navigation .item_media .icon {
  background-image: url(../images/icons/media.svg);
}

.main-navigation .item_club .icon {
  background-image: url(../images/icons/club.svg);
}

.main-navigation .item_club .icon {
  background-image: url(../images/icons/club.svg);
}

.main-navigation .item_shop .icon {
  background-image: url(../images/icons/shop.svg);
}

.main-navigation .item_games .icon {
  background-image: url(../images/icons/games.svg);
}

.main-navigation a {
  text-decoration: none;
  display: block;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  color: #fff;
}

.main-navigation .selected a {
  color: #ed4221;
}

.main-navigation li {
  float: left;
  width: 14.28571428%;
}

@media (min-width: 50em) {
  .main-navigation li {
    font-size: 12px;
    font-size: 0.75rem;
  }
}

@media (min-width: 50em) {
  .main-navigation li {
    font-size: 16px;
    font-size: 1rem;
  }
}

/*
*********************************************
* mobile navigation
*********************************************
*/
@media (min-width: 50em) {
  .mobile-navigation {
    display: none;
  }
}

.mobile-navigation .button {
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  background-color: transparent;
  background-image: url(../images/icons/mobile-menu.svg);
  position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.mobile-navigation .button.on {
  background-image: url(../images/icons/mobile-menu-close.svg);
}

.mobile-nav {
  margin: 20px;
}

.mobile-nav ul, .mobile-nav li {
  list-style: none;
}

.mobile-nav .icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 30px;
  margin: 0 auto;
  background-image: url(../images/icons/shows.svg);
  position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 20px;
  vertical-align: middle;
}

.mobile-nav .category {
  margin: 0 0 10px 0;
}

.mobile-nav .category li {
  margin: 5px 0;
}

.mobile-nav .category li a {
  vertical-align: middle;
  padding: 5px 20px;
  line-height: 30px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
  background: #fff;
  display: block;
  border-radius: 5px;
}

.mobile-nav .category ul {
  margin-left: 20px;
}

.mobile-nav .category > a {
  display: block;
  vertical-align: middle;
  padding: 5px 20px;
  background: #fff;
  line-height: 30px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
  border-radius: 5px;
}

.mobile-nav li {
  position: relative;
}

.mobile-nav .open-close-button {
  position: absolute;
  top: 3px;
  right: 10px;
}

.mobile-nav .category > .open-close-button {
  top: 8px;
}

.mobile-nav .selected > a,
.mobile-nav .category .selected > a {
  color: #ed4221;
}

.mobile-nav .item_media .icon {
  background-image: url(../images/icons/media.svg);
}

.mobile-nav .item_freunde .icon {
  background-image: url(../images/icons/freunde.svg);
}

.mobile-nav .item_shows .icon {
  background-image: url(../images/icons/shows.svg);
}

.mobile-nav .item_games .icon {
  background-image: url(../images/icons/games.svg);
}

.mobile-nav .item_club .icon {
  background-image: url(../images/icons/club.svg);
}

.mobile-nav .item_eltern .icon {
  background-image: url(../images/icons/eltern.svg);
}

.mobile-nav .item_shop .icon {
  background-image: url(../images/icons/shop.svg);
}

/*
*********************************************
* sub navigation
*********************************************
*/
.sub-navigation {
  list-style: none;
}

.sub-navigation li {
  list-style: none;
}

.sub-navigation a {
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  font-style: italic;
  color: #fff;
}

.sub-navigation .selected > a {
  color: #ed4221;
}

.sub-navigation > ul > li ul {
  display: none;
  font-size: 14px;
  font-size: 0.875rem;
  margin-left: 1em;
}

.sub-navigation > ul > li.selected ul {
  display: block;
}

.sub-navigation {
  margin-left: 1.75rem;
  margin-top: 1em;
}

/*
*********************************************
* layout body container
*********************************************
*/
.body-container {
  padding-bottom: 20px;
  zoom: 1;
}

.website-startpage .body-container {
  padding-top: 40px;
}

.body-container:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.aside2 {
  display: none;
}

.shop-app .aside1 {
  display: block;
}

@media (min-width: 50em) {
  .aside1 {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 25%;
    float: left;
    padding-left: 0.46296%;
    padding-right: 0.46296%;
    min-height: 400px;
  }
  .aside1 .gollo {
    margin: -20% 20% 0 -5%;
  }
  .main-column {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
    float: left;
    padding-left: 0.46296%;
    padding-right: 0.46296%;
  }
  .aside2 {
    display: block;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 25%;
    float: right;
    padding-left: 0.46296%;
    padding-right: 0.46296%;
  }
  .aside2 .papagallo {
    margin: -20% 5% 0 0;
  }
}

.main-column {
  padding-top: 40px;
}

.footer {
  clear: both;
}

@media (min-width: 50em) {
  .footer {
    height: 461px;
  }
}

.website-startpage .ContentTitle {
  display: none;
}

.website-startpage .main-column {
  padding-top: 20px;
}

.website-startpage .container {
  background: #6699ff;
}

.website-startpage .aside {
  display: none;
}

@media (min-width: 50em) {
  .website-startpage .aside {
    display: block;
  }
}

@media (min-width: 50em) {
  .website-startpage .main-column {
    width: 100%;
  }
}

@media (min-width: 50em) {
  .website-startpage .main-column {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
    float: left;
    padding-left: 0.46296%;
    padding-right: 0.46296%;
  }
}

.website-startpage .startpage-image {
  text-align: center;
}

.website-startpage .panel.facebook {
  text-align: right;
  padding-left: 0.46296%;
}

.website-startpage .panel.facebook .fb-like-box {
  border: 5px solid #ffff01;
  border-image-source: url("../images/rahmen.png");
  border-image-slice: 18;
  border-image-repeat: round;
  border-image-width: initial;
}

.website-startpage .panel.facebook .fb-like-box > span,
.website-startpage .panel.facebook .fb-like-box iframe {
  max-width: 100%;
}

.website-startpage .panel.news {
  border: 5px solid #ffff01;
  border-image-source: url("../images/rahmen.png");
  border-image-slice: 18;
  border-image-repeat: round;
  border-image-width: initial;
  width: 210px;
  max-width: 100%;
  margin-right: 0.46296%;
  min-height: 400px;
  background: #fff;
  padding: 6px;
  text-align: center;
}

.website-startpage .panel.news h2 {
  font-style: italic;
  margin: 12px 0;
  text-transform: uppercase;
}

@media (min-width: 50em) {
  .website-startpage .footer {
    background: url("../images/footer/tibet-6699ff.jpg") 0 0 no-repeat;
  }
}

.news-listing ul li {
  list-style: none;
  margin: 0 0 12px 0;
  text-transform: uppercase;
}

.news-listing ul li a {
  color: #ed4221;
}

/*
*********************************************
* skin colors and images
*********************************************
*/
.container {
  background: #6699ff;
}

.usa .container {
  background: #0099ff;
}

.mond .container {
  background: #7a05c4;
}

.nordpol .container {
  background: #0099cc;
}

.steinzeit .container {
  background: #0066cc;
}

.tibet .container {
  background: #6699ff;
}

.vikinger .container {
  background: #1aa8e6;
}

.piraten .container {
  background: #017bc2;
}

@media (min-width: 50em) {
  .footer {
    background-image: url("../images/footer/tibet-6699ff.jpg");
    background-position: 0 bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
  .tibet .footer {
    background-image: url("../images/footer/tibet-6699ff.jpg");
  }
  .usa .footer {
    background-image: url("../images/footer/usa-0099ff.jpg");
  }
  .mond .footer {
    background-image: url("../images/footer/mond-7a05c4.jpg");
  }
  .nordpol .footer {
    background-image: url("../images/footer/nordpol-0099cc.jpg");
  }
  .piraten .footer {
    background-image: url("../images/footer/piraten-017bc2.jpg");
  }
  .steinzeit .footer {
    background-image: url("../images/footer/steinzeit-0066cc.jpg");
  }
  .vikinger .footer {
    background-image: url("../images/footer/vikinger-1aa8e6.jpg");
  }
}

.sub-navigation-panel {
  display: none;
}

.shop-app .sub-navigation-panel {
  display: block;
}

.shop-app .sub-navigation-panel div.gollo {
  display: none;
}

@media (min-width: 50em) {
  .shop-app .sub-navigation-panel div.gollo {
    display: block;
  }
}

@media (min-width: 50em) {
  .sub-navigation-panel {
    display: block;
  }
  .sub-navigation-panel div.gollo {
    position: relative;
  }
  .sub-navigation-panel div.gollo .icon {
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 auto;
    margin-top: -12%;
    background-image: url(../images/icons/shows.svg);
    position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (min-width: 50em) and (min-width: 50em) {
  .sub-navigation-panel div.gollo .icon {
    width: 60px;
    height: 60px;
    margin-top: -9.5%;
  }
}

@media (min-width: 50em) {
  .media .sub-navigation-panel div.gollo .icon {
    background-image: url(../images/icons/media.svg);
  }
  .freunde .sub-navigation-panel div.gollo .icon {
    background-image: url(../images/icons/freunde.svg);
  }
  .shows .sub-navigation-panel div.gollo .icon {
    background-image: url(../images/icons/shows.svg);
  }
  .games .sub-navigation-panel div.gollo .icon {
    background-image: url(../images/icons/games.svg);
  }
  .club .sub-navigation-panel div.gollo .icon {
    background-image: url(../images/icons/club.svg);
  }
  .eltern .sub-navigation-panel div.gollo .icon {
    background-image: url(../images/icons/eltern.svg);
  }
  .shop .sub-navigation-panel div.gollo .icon {
    background-image: url(../images/icons/shop.svg);
  }
}

/*
*********************************************
* common definitions
*********************************************
*/
h1,
h2,
h3,
h4,
h5,
h6,
.main-navigation a,
.tree-navigation a,
.sub-navigation a {
  font-family: "Martel Sans", sans-serif;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 5px;
  border: 0 none;
}

th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}

th {
  text-align: left;
}

.main-column .Text,
.main-column .Image {
  margin: 0 0 20px 0;
}

.main-column .autoGenerated {
  margin-top: 20px;
}

.main-column h1.ContentTitle {
  font-size: 26px;
  font-size: 1.625rem;
  margin: 0 0 20px 0;
  text-align: center;
  text-transform: uppercase;
}

.main-column h2 {
  margin: 0 0 10px 0;
  font-size: 24px;
  font-size: 1.5rem;
}

.main-column .Download {
  margin: 0 0 10px 0;
}

.Text,
.Download,
.Image {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.partner-logos {
  zoom: 1;
  margin-bottom: 12px;
}

.partner-logos:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.partner-logos div.partner-logo {
  float: left;
  width: auto;
  margin: 0 12px 12px 0;
}

.Image.align-left {
  float: left;
  width: auto;
  margin: 0 12px 12px 0;
}

.Image.align-right {
  float: right;
  width: auto;
  margin: 0 0 12px 12px;
}

.inline-icons {
  zoom: 1;
  margin-bottom: 12px;
}

.inline-icons:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.inline-icons .inline-icon {
  float: left;
  width: auto;
  margin: 0 12px 12px 0;
}

.gallery {
  zoom: 1;
  margin-bottom: 12px;
}

.gallery img {
  max-width: 100%;
  height: auto;
}

.gallery:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.gallery .gallery-item {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 50%;
  float: left;
  padding-left: 0.46296%;
  padding-right: 0.46296%;
  margin-bottom: 0.46296%;
}

.gallery .gallery-item:nth-child(2n + 1) {
  margin-left: 0;
  margin-right: -100%;
  clear: both;
}

.gallery .gallery-item:nth-child(2n + 2) {
  margin-left: 50%;
  margin-right: -100%;
  clear: none;
}

@media (min-width: 50em) {
  .gallery .gallery-item {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 33.33333%;
    float: left;
    padding-left: 0.46296%;
    padding-right: 0.46296%;
  }
  .gallery .gallery-item:nth-child(3n + 1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
  }
  .gallery .gallery-item:nth-child(3n + 2) {
    margin-left: 33.33333%;
    margin-right: -100%;
    clear: none;
  }
  .gallery .gallery-item:nth-child(3n + 3) {
    margin-left: 66.66667%;
    margin-right: -100%;
    clear: none;
  }
}

@media (min-width: 75em) {
  .gallery .gallery-item {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 25%;
    float: left;
    padding-left: 0.46296%;
    padding-right: 0.46296%;
  }
  .gallery .gallery-item:nth-child(4n + 1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
  }
  .gallery .gallery-item:nth-child(4n + 2) {
    margin-left: 25%;
    margin-right: -100%;
    clear: none;
  }
  .gallery .gallery-item:nth-child(4n + 3) {
    margin-left: 50%;
    margin-right: -100%;
    clear: none;
  }
  .gallery .gallery-item:nth-child(4n + 4) {
    margin-left: 75%;
    margin-right: -100%;
    clear: none;
  }
}

.gallery-rows-2items {
  zoom: 1;
  margin-bottom: 12px;
}

.gallery-rows-2items:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.gallery-rows-2items img {
  max-width: 100%;
  height: auto;
}

.gallery-rows-2items .gallery-2items {
  margin-bottom: 2.77778%;
}

@media (min-width: 50em) {
  .gallery-rows-2items .gallery-2items {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 50%;
    float: left;
    padding-left: 1.38889%;
    padding-right: 1.38889%;
  }
  .gallery-rows-2items .gallery-2items:nth-child(2n + 1) {
    margin-left: 0;
    margin-right: -100%;
    clear: both;
  }
  .gallery-rows-2items .gallery-2items:nth-child(2n + 2) {
    margin-left: 50%;
    margin-right: -100%;
    clear: none;
  }
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?r4xxez");
  src: url("../fonts/icomoon.eot?r4xxez#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?r4xxez") format("truetype"), url("../fonts/icomoon.woff?r4xxez") format("woff"), url("../fonts/icomoon.svg?r4xxez#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-keyboard_arrow_down:before {
  content: "";
}

.icon-keyboard_arrow_left:before {
  content: "";
}

.icon-keyboard_arrow_right:before {
  content: "";
}

.icon-keyboard_arrow_up:before {
  content: "";
}

.icon-home2:before {
  content: "";
}

.icon-image:before {
  content: "";
}

.icon-music:before {
  content: "";
}

.icon-play:before {
  content: "";
}

.icon-film:before {
  content: "";
}

.icon-video-camera:before {
  content: "";
}

.icon-dice:before {
  content: "";
}

.icon-file-picture:before {
  content: "";
}

.icon-quotes-left:before {
  content: "";
}

.icon-quotes-right:before {
  content: "";
}

.icon-spinner3:before {
  content: "";
}

.icon-switch:before {
  content: "";
}

.icon-menu2:before {
  content: "";
}

.icon-menu22:before {
  content: "";
}

.icon-menu3:before {
  content: "";
}

.icon-menu4:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-minus:before {
  content: "";
}

.icon-cross:before {
  content: "";
}

.icon-checkmark:before {
  content: "";
}

.icon-circle-up:before {
  content: "";
}

.icon-circle-right:before {
  content: "";
}

.icon-circle-down:before {
  content: "";
}

.icon-circle-left:before {
  content: "";
}

.icon-new-tab:before {
  content: "";
}

.icon-mail2:before {
  content: "";
}

.icon-google-plus:before {
  content: "";
}

.icon-twitter2:before {
  content: "";
}

.icon-youtube:before {
  content: "";
}

.icon-vimeo:before {
  content: "";
}

.icon-linkedin:before {
  content: "";
}

.icon-file-pdf:before {
  content: "";
}

.icon-file-openoffice:before {
  content: "";
}

.icon-file-word:before {
  content: "";
}

.icon-file-excel:before {
  content: "";
}

.icon-libreoffice:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-facebook:before {
  content: "";
}

.icon-twitter:before {
  content: "";
}

.icon-mail:before {
  content: "";
}

.icon-print:before {
  content: "";
}

.icon-info:before {
  content: "";
}

.icon-download-pdf:before {
  content: "";
}

.icon-download .path1:before {
  content: "";
  color: black;
}

.icon-download .path2:before {
  content: "";
  margin-left: -1em;
  color: white;
}

.icon-download .path3:before {
  content: "";
  margin-left: -1em;
  color: white;
}

.icon-download .path4:before {
  content: "";
  margin-left: -1em;
  color: white;
}

.icon-pfeil:before {
  content: "";
}

.icon-pfeil-fett:before {
  content: "";
}

.icon-spinner:before {
  content: "";
}

.icon-menu:before {
  content: "";
}

.icon-rss:before {
  content: "";
}

.Download .SizeAndTargetInfo {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.Download a:before {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Download a.pdf:before {
  content: "";
  margin-right: .5rem;
  text-decoration: none !important;
}
